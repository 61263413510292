
import "./Membership.scss";
import image from "./logo.png";

export default function Membership() {
  
  
  return (
    <div className="login">
      <div className="top">
        <div className="wrapper">
          <img
            className="logo"
            src={image}
            alt=""
          />
        </div>
      </div>
      <div className="container">
        <form>
          <h1>No cuenta con una membresía activa</h1>
          <span>
            Comuniquese con soporte info@vuela.bo
          </span>
         
        </form>
      </div>
    </div>
  );
}
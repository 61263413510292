import { loginFailure, loginStart, loginSuccess } from "./AuthActions";
import { Link,useHistory } from "react-router-dom";

export const Timeout = (time) => {
	let controller = new AbortController();
	setTimeout(() => controller.abort(), time * 1000);
	return controller;
};

export const login = async (user, dispatch) => {

  console.log(user);
  dispatch(loginStart("validando ..."));
   let data;
    data={
      "email": "emanuel@prueba.com",
      "password": "robladillo"
    }


    await fetch("https://tv1.yottalan.com/auth/login/",{
      signal: Timeout(2).signal,
      method:"POST",
      body:JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((response)=>{
      
      if (response.ok) {
      
        response.json().then((result)=>{
          console.log("errorrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",result);
          dispatch(loginSuccess(result.access,"https://tv1.yottalan.com"));
          localStorage.setItem("url", "https://tv1.yottalan.com");

          //console.log("tokennnnnnnnnnnn")

          //console.warn("result",result.access);
          //localStorage.setItem("user", JSON.stringify(result.access));
        })
        
      }
      else{
        
        response.json().then((result)=>{
          console.warn("resultaquiiiiiiiiiiiiiiiii",result.message);
          dispatch(loginFailure(result.message));
        })

      }

    }).catch((err) => 
    
    {console.log("eorrrrrrrrrrrrr")
    localStorage.setItem("url", "http://core1.hdlatam.tv");
     }
    
    );



    await fetch("http://core1.hdlatam.tv/auth/login/",{
      signal: Timeout(2).signal,
      method:"POST",
      body:JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((response)=>{
      
      if (response.ok) {
      
        response.json().then((result)=>{
          dispatch(loginSuccess(result.access,"http://core1.hdlatam.tv"));
          //console.log("tokennnnnnnnnnnn")

          //console.warn("result",result.access);
          //localStorage.setItem("user", JSON.stringify(result.access));
        })
        
      }
      else{
        
        response.json().then((result)=>{
          console.warn("result",result.message);
          dispatch(loginFailure(result.message));
        })

      }

    }).catch((err) => console.log("eorrrrrrrrrrrrr"));


  };


  export const register = async (user, dispatch) => {
    console.log("register datatatatat user");
    //console.log(user);
    dispatch(loginStart("validando ..."));
     let data;
      data={
        "email": "emanuel@prueba.com",
        "password": "robladillo"
      }
  
  
      await fetch("http://core1.hdlatam.tv/register/",{
        
        method:"POST",
        body:JSON.stringify(user),
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then((response)=>{
        console.log("rptaaaaaaaaaaa");
        if (response.ok) {
        console.log("vergaaaaaa");
          response.json().then((result)=>{
            //dispatch(loginSuccess(1,"https://tv2.yottalan.com"));
            console.log("tokennnnnnnnnnnngaaaaaaaa")
            dispatch(loginFailure("Cuenta creada correctamente "));
            console.warn("result",result.access);
            //localStorage.setItem("user", JSON.stringify(result.access));
          })
          
        }
        else{
          
          console.log("vergaaaaaa2");
          response.json().then((result)=>{
            console.warn("result",result.email);
            //dispatch(loginSuccess(result.email,"https://tv2.yottalan.com"));
            dispatch(loginFailure(result.email));
          })
  
        }
  
      }).catch((err) => console.log("eorrrrrrrrrrrrr"));
  
  
    };


    export const recuperar = async (user, dispatch) => {
      console.log("recuperarrrrrrrrrr");
      console.log(user);
      dispatch(loginStart("validando ..."));
       let data;
        data={
          "email": "emanuel@prueba.com",
          "password": "robladillo"
        }
    
    
        await fetch("http://core1.hdlatam.tv/forgot/password/",{
          
          method:"POST",
          body:JSON.stringify(user),
          headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
        }).then((response)=>{
          console.log("recuperar");
          if (response.ok) {
          console.log("vergaaaaaa");
            response.json().then((result)=>{
              //dispatch(loginSuccess(1,"https://tv2.yottalan.com"));
              console.log("tokennnnnnnnnnnngaaaaaaaa")
              dispatch(loginFailure("Cuenta creada correctamente "));
              console.warn("result",result.status);
              //localStorage.setItem("user", JSON.stringify(result.access));
            })
            
          }
          else{
            
            console.log("vergaaaaaa2");
            response.json().then((result)=>{
              console.warn("result",result.email);
              //dispatch(loginSuccess(result.email,"https://tv2.yottalan.com"));
              dispatch(loginFailure(0));
            })
    
          }
    
        }).catch((err) => console.log("eorrrrrrrrrrrrr"));
    
    
      };
    
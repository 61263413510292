import { useRef } from "react";
import { useState } from "react";
import { Link,useHistory } from "react-router-dom";
import "./register.scss";
import React from 'react';
import ReactDOM from 'react-dom';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { register } from "../../authContext/apiCalls";
import { useContext } from "react";
import { AuthContext } from "../../authContext/AuthContext";
import image from "./logo.png";

export default function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [first_name, setNombre] = useState("");
  const [last_name, setApellido] = useState("");
  const [cellphone, setTelefono] = useState("");
  const [prefijo, setPrefijo] = useState("");
  const [validtel, setValidtel] = useState("");
  const [errorform, setErrorform] = useState(null);
  const { dispatch } = useContext(AuthContext);
  let usert=useContext(AuthContext).error;
  let u=useContext(AuthContext).user;

  let history = useHistory();

  

  function handlePhoneNumberChange (
    isValid,
    rawValue,
    countryData,
    formattedValue,
    extension
  ) {
    setValidtel(isValid);
    if(isValid){
      let telefonof;
      telefonof="+"+countryData.dialCode+"-"+rawValue;
      console.log("enviar",telefonof); 
      setTelefono(telefonof);
    }
  }
  const handleRegister = (e) => {
    e.preventDefault();
    setErrorform("");
    if(first_name.toString().length<2){
      setErrorform("Ingrese un nombre válido ");
    }else{
      if( last_name.toString().length<2)
      {setErrorform("Ingrese un apellido válido");}
      else{

      if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(email)){
      
        if(password.toString().length < 5){
          setErrorform("Ingrese un password mayor a 5 caracteres")
        }else{
          if(!validtel){
            setErrorform("Ingrese un móvil valido")
          }else{

            console.log("todo llenito");
            register({ email, password,first_name,last_name,cellphone }, dispatch);
            
          }
        }

      } else {
        setErrorform("Ingrese un email válido");
      }

    }
  }
  
  };
 
  return (
    <div className="register">
      <div className="top">
        <div className="wrapper">
          <img
            className="logo"
            src="https://www.hdplayonline.com/assets/img/logo.png"
            alt=""
          />
        </div>
      </div>
      <div className="container">
        <form>
          <h1>Regístrate</h1>
          <br></br>

          <input
            type=""
            placeholder="Nombre"
            onChange={(e) => setNombre(e.target.value)}
          />
          <input
            type=""
            placeholder="Apellidos "
            onChange={(e) => setApellido(e.target.value)}
          />
          <input
            type="email"
            placeholder="Email "
            onChange={(e) => setEmail(e.target.value)}
          />
        <input
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />

          <IntlTelInput
            preferredCountries={['bo']}
            onPhoneNumberChange={handlePhoneNumberChange}
            placeholder="Móvil"  
          />
          <p>{errorform}</p>          
          <h4>{usert}</h4>
          
          
          <button className="loginButton" onClick={handleRegister} >
            Ingresar
          </button>

          
        </form>
      </div>
    </div>  );
}

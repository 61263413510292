import "./listaItem.scss";
import ReactPlayer from 'react-player'
import { Link } from "react-router-dom";
import {
  PlayArrow,
  Add,
  ThumbUpAltOutlined,
  ThumbDownOutlined,
} from "@material-ui/icons";
import { useState } from "react";

export default function ListaItem({ index, item , catego }) {
  const [isHovered, setIsHovered] = useState(false);

  async function vista(){
    console.log("cnalaaaaaaaaaaaaaaaaaaaaaaaa")
    console.log(item)
    let data;
    data={
      channel:  item.id,
      deviceapp: 'web',
      modelo : navigator.userAgent.toString()

    }

    console.log(data);
    console.log("finnnnnnnnnnnnnnnnnnnnnnnnn")
    var token2=localStorage.getItem("user").replace(/["]+/g, '');

    fetch("http://core1.hdlatam.tv/channels/track/",{
      method:"POST",
      body:JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${token2}`

        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((response)=>{
      console.log("resultado")
      console.log(response)
      if (response.ok) {
      
        response.json().then((result)=>{
          console.log("tokennnnnnnnnnnn")

          console.warn("result",result.access);
          //localStorage.setItem("user", JSON.stringify(result.access));
        })
        
      }
      else{
        response.json().then((result)=>{
          console.warn("result",result.message);
  
        })

      }

    })



  }
  const trailer =
    "https://player.vimeo.com/external/371433846.sd.mp4?s=236da2f3c0fd273d2c6d9a064f3ae35579b2bbdf&profile_id=139&oauth2_token_id=57447761";
    return (
    
      <Link to ={{ pathname:`/watch/${index}-${catego==undefined?0:catego}`,movie:item}} onClick={vista}>

      <div className="listaItema">

          <img src={item.image} alt=""/>

      </div>
    </Link>    
  );
}

import { InfoOutlined, PlayArrow } from "@material-ui/icons";
import "./featured.scss";
import Watch from "../../pages/watch/Watch";
import { Link,useHistory } from "react-router-dom";
import image from "./logo.png";

export default function Featured({ type,cate }) {
  let history = useHistory();

  function handleChange(value) {
    history.push(`/`);
    history.push(`categoria/${value}`);
  }
  return (
   
   <div className="featured">
      
        <div className="category">
          <span> Categorías</span>
          <select name="genre" id="genre" onChange={event => handleChange(event.target.value)} >
           
            <option value={0}>Todos</option>
            {
              cate.map((item,i) => (
                
                <option value={item.id}>{item.name}</option>    

              ))
            }
          </select>
        </div>
      
      <div className="info">
        <img
          src="https://www.hdplayonline.com/assets/img/logo.png"
          alt="hdplay"
        />
        <h1 className="desc">
        Disfruta de la mejor programación nacional e internacional en vivo con toda la familia.
        </h1>
        
      </div>
    </div>
  );
}
